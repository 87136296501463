
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import CustomFunctions from '../../dependencies/custom/custom-functions';
import { Space, Button, Input, Radio, Checkbox, Alert, Tag, Tooltip, Divider, Modal, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import useTable from '../../hooks/table';


const Deferments = (props) => {
    const valuesStore = ValuesStore();
    const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd('tables_metadata', 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const [btnPermissions, setBtnPermissions] = useState({});

    const [indexNumber, setIndexNumber] = useState();
    const [selectedTable, setSelectedTable] = useState('');


    const [studentFound, setStudentFound] = useState(false);
    const [studentInfoBanner, setStudentInfoBanner] = useState();
    const [alertMsg, setAlertMsg] = useState("Student details will appear here");


    const deferTable = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_data`,
        'post',
        'result',
        'totalCount',
        'id',
        {},
        { table: 'deferments', fields: ['*'] });


    const billExpTable = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 20,
                position: ['bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_data`,
        'post',
        'result',
        'totalCount',
        'id',
        {},
        { table: 'bill_exemptions', fields: ['*'] });


    const billExptColumns = ([
        {
            title: 'Index Number',
            dataIndex: 'index_no',
            ...billExpTable.getColumnSearchProps('index_no')
        },
        {
            title: 'Acad. Yr',
            dataIndex: 'acad_year',
            ...billExpTable.getColumnSearchProps('acad_year')
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
        },
        {
            title: 'Date',
            dataIndex: 'date_inserted',
            ...billExpTable.getColumnSearchProps('date_inserted'),
            render: (v, record) => {
                return new Date(utils.formatDate(v)).toLocaleDateString();
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Button className='btn-primary border-0' onClick={e => setNFetchStudent(record)}><i className='fas fa-user' /></Button>
                    {btnPermissions?.delete_balances && <Tooltip title="Delete Deferment">
                        {del.confirm(
                            `${Settings.backend}/delete`,
                            record,
                            'Are you sure to delete this item',
                            { tableName: 'bill_exemptions', where: 'id', whereType: 'closed' },
                            <Button className='btn-danger border-0'>  <i className='fas fa-trash text-dangerx' /></Button>,
                            undefined, undefined, undefined
                        )}
                    </Tooltip>}
                </Space>
            },
        },
    ]);

    const deferColumns = ([
        {
            title: 'Index Number',
            dataIndex: 'index_no',
            ...deferTable.getColumnSearchProps('index_no')
        },
        {
            title: 'Acad. Yr',
            dataIndex: 'acad_year',
            ...deferTable.getColumnSearchProps('acad_year')
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    <Button className='btn-primary border-0' onClick={e => setNFetchStudent(record)}><i className='fas fa-user' /></Button>

                    {btnPermissions?.delete_balances && <Tooltip title="Delete Deferment">
                        {del.confirm(
                            `${Settings.backend}/delete`,
                            record,
                            'Are you sure to delete this item',
                            { tableName: 'deferments', where: 'id', whereType: 'closed' },
                            <Button className='btn-danger border-0'>  <i className='fas fa-trash text-dangerx' /></Button>,
                            undefined, undefined, undefined
                        )}
                    </Tooltip>}
                </Space>
            },
        },
    ]);


    async function setNFetchStudent(record) {
        setIndexNumberNUpdate(record?.index_no);
        await getStudentDetails2(record?.index_no);
    }


    function repushTxn(record) {
        setNFetchStudent(record);
        // add.setRecord({ amt: record?.amount, txn_id: record?.txn_id, bank: record?.bank_code, teller: record?.teller, date: record?.date });        
    }

    useMemo(() => {
        setTimeout(() => {
            add.setTblName('DEFEREMENTS');
            add.setSaveCompleted(false);
        }, 500);
        showBtnUsingPermissions();


        billExpTable.setColumns(billExptColumns);
        deferTable.setColumns(deferColumns);


    }, [add.saveCompleted, edit.saveCompleted, del.saveCompleted, deferTable.extraFetchParams, billExpTable.extraFetchParams, btnPermissions]);





    function showBtnUsingPermissions() {
        if (Object.keys(btnPermissions) <= 0) {

            const permList = [
                { perm: 'save_receipt_manually', table: '' },
                { perm: 'delete_balances', table: 'balances' },
            ];
            permList.forEach(async v => {
                const perm = await utils.hasPermission(v.perm, v.table, valuesStore);
                setBtnPermissions(r => ({ ...r, [v.perm]: perm }))
            });
        }
    }





    async function getStudentDetails() {
        setIndexNumberNUpdate(indexNumber);
        const res = await utils.requestWithReauth('post', `${Settings.backend}/get_student`, null, { indexNumber });
        if (res?.result?.length) {
            setStudentFound(true);
            studentDetails(res?.result[0]);
        } else {
            setAlertMsg('Student not found');
            setStudentFound(false);
        }
    }

    async function getStudentDetails2(indexNo) {
        setIndexNumberNUpdate(indexNumber);
        const res = await utils.requestWithReauth('post', `${Settings.backend}/get_student`, null, { indexNumber: indexNo });
        if (res?.result?.length) {
            setStudentFound(true);
            studentDetails(res?.result[0]);
        } else {
            setAlertMsg('Student not found');
            setStudentFound(false);
        }
    }

    function studentDetails(studentInfo) {
        const details = <div className="container">
            <div className="row row-cols-3 p-2 blue lighten-5 rounded">
                <div className="col">
                    First Name: {studentInfo?.fname}<br />
                    Last Name: {studentInfo?.lname}<br />
                    Middle Name: {studentInfo?.mname}<br />
                </div>
                <div className="col">
                    Index Number: {studentInfo?.index_no}<br />
                    Program: {studentInfo?.program_name}<br />
                    Level: {studentInfo?.current_level}
                </div>
                <div className="col">
                    Session: {studentInfo?.session}<br />
                    Current Semester :{studentInfo?.current_sem}
                </div>
            </div>
        </div>;
        setStudentInfoBanner(details);
    }



    const onTabChange = (e) => {
        switch (e.target.value) {
            case 'deferment': {
                add.setTblName('DEFEREMENTS');
                add.setSaveCompleted(false);
                setSelectedTable('deferments');
                break;
            }
            case 'bill_exemption': {
                add.setTblName('BILL_EXEMPTIONS');
                add.setSaveCompleted(false);
                setSelectedTable('bill_exemptions');
                break;
            }
        }
    };

    function submitForm() {
        if (!indexNumber) {
            message.error('Please set index number');
            return;
        }
        add.save(undefined, { tbl: selectedTable });
    }

    function setIndexNumberNUpdate(v) {
        add.setRecord({ ...add.record, index_no: v });
        setIndexNumber(v)
    }

    return (
        <>
            <div className='container' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    <div className='col-md-12 mb-2'>
                        <div className='mb-2'>
                            {studentFound ? studentInfoBanner : <Alert message={alertMsg} type="info" showIcon />}
                        </div>

                        <div className='d-flex'>
                            <Input
                                type='text'
                                placeholder='Enter Index Number/Applicant ID'
                                value={indexNumber}
                                onChange={e => setIndexNumberNUpdate(e.target.value)}
                                className='w-100 me-3'
                                size="large"
                                onPressEnter={e => getStudentDetails()} />
                            <Button size='large' type='primary' onClick={e => getStudentDetails()} > <i className='fas fa-search me-2' /> Fetch Student</Button>
                        </div>
                        <div className='pt-2'>
                            <Radio.Group /*defaultValue="deferment"*/ onChange={onTabChange} buttonStyle="solid">
                                <Radio.Button value="deferment">Deferment</Radio.Button>
                                <Radio.Button value="bill_exemption">Bill Exemption</Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                    <div className='col-md-12 mb-2'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='borderx rounded p-2'>
                                    <label className='fw-bold'>Forms</label>
                                    {add.form}
                                    {/* {btnPermissions?.save_receipt_manually && <Button onClick={e => submitEntry('cr')} className='btn-success border-0' icon={<i className='fas fa-paper-plane me-2' />}>Submit</Button>} */}
                                </div>
                                <div>
                                    <Button onClick={e => submitForm()} className='btn-success border-0' icon={<i className='fas fa-paper-plane' />}>Submit</Button>
                                </div>
                                <div>
                                    <label className='fw-bold'>Bill Exemptions</label>
                                    {billExpTable.table}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <label className='fw-bold'>Deferments</label>
                                {deferTable.table}
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            {/* {drawer.drawerJSX()} */}
        </>
    );
}

export default memo(Deferments);