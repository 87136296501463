
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, DatePicker, Divider, Input, Checkbox, Select, Radio } from 'antd';
// import { Card, Input, Avatar, Dropdown, Button, Affix, Space, Modal, Empty, Badge, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import useExcel from '../../hooks/excel';
import ReactToPrint from 'react-to-print';


const DebtorBalance = (props) => {
    const valuesStore = ValuesStore();
    const meta = 'other_metadata';
    const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd(meta, 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const [dateRange, setDateRange] = useState([]);
    const navigate = useNavigate();
    const [queryLoading, setQueryLoading] = useState(false);
    const plainOptions = ['Firstname', 'Surname', 'Middlename', 'Sex', 'DOB', 'Email', 'Telephone', 'IndexNo', 'Program', 'Session', 'StudentType',
        'Level', 'Campus', 'Faculty', 'Department', 'Bill', 'Payments', 'Balance', 'Currency', 'AcPayment', 'AcBalance', 'AcBill', 'AcPercentagePayment'];
    const colOptions = plainOptions.map(v => ({ label: v, value: v }));
    const [columns, setColumns] = useState([]);
    const [acadYear, setAcadYear] = useState('');
    const [percentage, setPercentage] = useState(0);
    const excel = useExcel();
    const printable = useRef();
    const [pdf, setPDF] = useState(undefined);
    const [balanceType, setBalanceType] = useState('All');
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['topRight', 'bottomRight'],
                hideOnSinglePage: true
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        undefined,
        undefined,
        'result',
        'totalCount',
        'id',
        { /*alias: 'LIKE', acadyr: 'LIKE', semester: 'IN', end_date: 'IN', is_active: 'IN' */ },
        { table: 'txn', fields: ['*'] });

    const detailedOption = {
        Firstname: {
            title: 'Firstname',
            dataIndex: 'fname'
        },
        Surname: {
            title: 'Lastname',
            dataIndex: 'lname'
        },
        Middlename: {
            title: 'Middlename',
            dataIndex: 'mname'
        },
        Email: {
            title: 'Email',
            dataIndex: 'personal_email'
        },
        Telephone: {
            title: 'Telephone',
            dataIndex: 'telephone'
        },
        Sex: {
            title: 'Sex',
            dataIndex: 'sex'
        },
        DOB: {
            title: 'DOB',
            dataIndex: 'dob'
        },
        Level: {
            title: 'Level',
            dataIndex: 'current_level',
            ...table.getColumnSearchProps('level')
        },
        IndexNo: {
            title: 'IndexNo',
            dataIndex: 'index_no',
            ...table.getColumnSearchProps('index_no'),
        },
        Session: {
            title: 'Session',
            dataIndex: 'session'
        },
        StudentType: {
            title: 'StudentType',
            dataIndex: 'student_type'
        },
        Campus: {
            title: 'Campus',
            dataIndex: 'campus'
        },
        Faculty: {
            title: 'Faculty',
            dataIndex: 'faculty_id'
        },
        Department: {
            title: 'Department',
            dataIndex: 'dept_id'
        },
        Program: {
            title: 'Program',
            // filterSearch: true,
            dataIndex: 'name',
        },
        Payments: {
            title: 'Payments',
            dataIndex: 'totalPayments'
        },
        Bill: {
            title: 'Bill',
            dataIndex: 'totalBill'
        },
        Currency: {
            title: 'Currency',
            dataIndex: 'currency'
        },
        Balance: {
            title: 'Balance',
            dataIndex: 'totalBalance'
        },
        AcPayment: {
            title: 'AmtPaid',
            dataIndex: 'amtPaid'
        },
        AcBalance: {
            title: 'Acad. Yr. Balance',
            dataIndex: 'balance'
        },
        AcBill: {
            title: 'Bill',
            dataIndex: 'bill'
        },
        AcPercentagePayment: {
            title: 'Payment Percentage',
            dataIndex: 'percentagePayment'
        },
    };

    function resetTable() {
        navigate(0);
    }



    function onAddColumnChange(list) {
        let cols = [];
        list.forEach(v => {
            const info = detailedOption[v];
            cols.push(info);
        });
        setColumns(cols);
    }



    const colFilters = [
        {
            filter: 'session',
            sql: "SELECT session,alias FROM sessions",
            key: 'alias',
            value: 'session'
        },
        {
            filter: 'level',
            sql: "SELECT level FROM level",
            key: 'level',
            value: 'level'
        },
        {
            filter: 'course_code',
            sql: "SELECT course_name,course_code FROM courses",
            key: 'course_code',
            value: 'course_name'
        },
        {
            filter: 'program',
            sql: "SELECT name,alias FROM programs WHERE show_hide =1 ORDER BY name ",
            key: 'alias',
            value: 'name'
        },
        {
            filter: 'semester_id',
            sql: 'SELECT semester,alias FROM semester',
            key: 'alias',
            value: 'semester'
        },
        {
            filter: 'mount_type',
            sql: 'SELECT distinct mount_type FROM course_lecturer',
            key: 'mount_type',
            value: 'mount_type'
        }
    ];


    async function editOnOk() {
        let res = await edit.save(undefined,
            `${Settings.backend}/edit`,
            'advert',
            { tbl: 'advert', where: 'id', whereType: 'closed' },
        );
    }

    async function addOnOk() {
        let res = await add.save(`${Settings.backend}/add`, { tbl: 'advert' })
    }

    function genFilters() {
        setTimeout(() => {
            valuesStore.setValue(meta, [
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'index_no', col_real_name: 'IndexNo', type: 'text',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'program', col_real_name: 'Program', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT name,alias from programs","key":"alias","value":"name"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'session', col_real_name: 'Session', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT session,alias from sessions","key":"alias","value":"session"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'student_type', col_real_name: 'Student Type', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT type from student_type","key":"type","value":"type"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'current_level', col_real_name: 'Level', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT level from level","key":"level","value":"level"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'campus', col_real_name: 'Campus', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT name,alias from organizational_type WHERE type = 2","key":"alias","value":"name"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'faculty_id', col_real_name: 'Faculty', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT name,alias from organizational_type WHERE type = 4","key":"alias","value":"name"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'txn_filters',
                    column_name: 'dept_id', col_real_name: 'Department', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT name,alias from organizational_type WHERE type = 5","key":"alias","value":"name"}',
                    backend_visible: 1,
                    rank: 1
                }
            ]);
            add.setTblName('txn_filters');
        }, 1000);
    }


    useMemo(() => {
        genFilters();
        table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);
        console.log('looping');
        // table.fetchData();
    }, [add.saveCompleted, edit.saveCompleted, del.saveCompleted, table.extraFetchParams]);

    async function query() {
        setQueryLoading(true);
        const range = utils.getDateFromMoment(dateRange);
        let rng = null;
        if (range) {
            const { startDate, endDate } = range;
            rng = { startDate, endDate }
        }
        let res = await utils.requestWithReauth('post', `${Settings.backend}/get_debtors`, null, { filters: add.record, date_range: rng });
        let final = [];
        switch (balanceType) {
            case "Debtors": {               
                final = res?.result.filter(v => {                    
                    return parseFloat(v.totalBalance) > 0;
                });
                break;
            }
            case "Creditors": {
                final = res?.result.filter(v => {
                    return parseFloat(v.totalBalance) < 0;
                });
                break;
            }
            case "Settled": {
                final = res?.result.filter(v => {
                    return parseFloat(v.totalBalance) == 0;
                });
                break;
            }
            default: {
                final = res['result'];
            }
        }
        table.setData(final);
        if (columns.length) {
            table.setColumns(columns);
        } else {
            const preset = [
                { dataIndex: "fname", title: "Firstname" },
                { dataIndex: "lname", title: "Surname" },
                { dataIndex: "mname", title: "Middlename" },
                { dataIndex: "index_no", title: "IndexNo." },
                { dataIndex: "sex", title: "Sex" },
                { dataIndex: "name", title: "Program" },
                { dataIndex: "telephone", title: "Phone" },
                { dataIndex: "totalBill", title: "Bill" },
                { dataIndex: "totalPayments", title: "Payment" },
                { dataIndex: "totalBalance", title: "Balance" },
            ];
            table.setColumns(preset);
            setColumns(preset);
        }
        setQueryLoading(false);
    }

    async function query2() {
        setQueryLoading(true);
        let res = await utils.requestWithReauth('post', `${Settings.backend}/get_acadyr_balances`, null, { filters: add.record, acadYear: acadYear, percentage: percentage });
        const final = res?.result?.map(v => {
            const bio = v?.bio;
            const details = v?.acadYearDetails;
            return { ...bio, ...details };
        });

        table.setData(final);
        if (columns.length) {
            table.setColumns(columns);
        } else {
            const preset = [
                { dataIndex: "fname", title: "Firstname" },
                { dataIndex: "lname", title: "Surname" },
                { dataIndex: "mname", title: "Middlename" },
                { dataIndex: "index_no", title: "IndexNo." },
                { dataIndex: "sex", title: "Sex" },
                { dataIndex: "name", title: "Program" },
                { dataIndex: "telephone", title: "Phone" },
                { dataIndex: "bill", title: "Bill" },
                { dataIndex: "amtPaid", title: "AmtPaid" },
                { dataIndex: "balance", title: "Acad. Yr. Balance" },
                { dataIndex: "percentagePayment", title: "Payment Percentage" },
            ];
            table.setColumns(preset);
            setColumns(preset);
        }
        setQueryLoading(false);
    }

    function exportXLS() {
        const headings = [];
        const dataIndexes = [];
        columns.forEach(v => {
            headings.push(v.title);
            dataIndexes.push(v.dataIndex);
        });

        const data = table?.data?.map(v => {
            let obj = {};
            dataIndexes.forEach(key => {
                obj[key] = v[key];
            });
            return obj;
        });

        excel.exportXLSX(headings, data, 'sheet1', `debtors_${new Date()}.xlsx`);
    }

    function onColumnAdded(list) {
        let cols = [];
        list.forEach(v => {
            const info = detailedOption[v];
            cols.push(info);
        });
        setColumns(cols);
    }

    function onDebtorsChange(checked) {
        setBalanceType(checked.target.value);
    }

    return (
        <>
            {/* {console.log('render render')} */}
            <div className='container' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    {/* <div className='col-md-12'>
                        <Divider orientation='left'>Columns</Divider>
                        <Checkbox.Group className='p-2 rounded borderx bg-white d-flex flex-column' options={plainOptions} onChange={onAddColumnChange} />
                    </div> */}
                    <div className='col-md-12'>
                        <Divider orientation='left'>Columns</Divider>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            filterOption
                            placeholder="Please select your desired columns"
                            onChange={onColumnAdded}
                            // value={columns}
                            options={colOptions}
                        />
                    </div>
                    <div className='col-md-12'>
                        <Divider orientation='left'>Filters</Divider>
                        <div className='row borderx bg-whitex p-2x roundedx'>
                            <div className='col-md-6'>
                                {add.form?.map((v, i) => {
                                    return i < Math.ceil(add.form.length / 2) ? v : undefined
                                })}
                            </div>
                            <div className='col-md-6'>
                                {add.form?.map((v, i) => {
                                    return i >= Math.ceil(add.form.length / 2) ? v : undefined
                                })}
                            </div>
                            <div className='col-md-6'>
                                <Space className=''>
                                    <Radio.Group options={['Debtors', 'Creditors', 'Settled', 'All']} defaultValue={'All'} onChange={onDebtorsChange} />
                                    <Button loading={queryLoading} className='btn-success border-0' onClick={e => query()}><i className='fas fa-filter me-2' />Query</Button>
                                    <Button className='btn-danger border-0' onClick={e => resetTable()}><i className='fas fa-filter me-2' /> Clear All Filters</Button>
                                    <Button className='btn-warning border-0' onClick={e => exportXLS()}><i className='fas fa-file me-2' /> Excel</Button>

                                    {/* <ReactToPrint
                                        trigger={() => <Button><i className='fas fa-print' />&emsp;Print out!</Button>}
                                        content={() => printable.current}
                                    /> */}
                                </Space>
                            </div>
                            <div className='col-md-6 mb-2'>
                                <Space>
                                    <Input placeholder='Academic Year' value={acadYear} onChange={e => setAcadYear(e.target.value)} />
                                    <Input type='number' placeholder='Percentage' value={percentage} onChange={e => setPercentage(e.target.value)} />
                                    <Button loading={queryLoading} className='btn-success border-0' onClick={e => query2()}><i className='fas fa-filter me-2' />Query Percentage</Button>
                                </Space>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-12 mt-1'>
                        {table.table}
                    </div>
                    <div className='col-md-12 mt-1' ref={printable}>
                        {pdf}
                    </div>
                </div>
            </div>
            {edit.editModal('Edit Item', editOnOk)}
            {add.addModal('Assign Course', addOnOk)}
        </>
    );
}

export default memo(DebtorBalance);